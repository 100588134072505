// Tiles

.tiles {
	.tile {
		display: grid;
		position: relative;
		background: $white;
		height: auto;
		margin: 0;
		border-bottom: none;
		border-radius: 1rem;
		box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.1);
		grid-template-rows: 9.375rem 1fr;
		overflow: hidden;
		color: $black;

		&.no-desc {
			.info {
				.title {
					margin-bottom: 0;

					&::after {
						content: none;
					}
				}
			}
		}

		+ .tile {
			margin-top: 2rem;
		}

		.image {
			position: relative;
			background: $light-grey url('/img/placeholder-page-header.jpg') no-repeat center center / cover;
			width: 100%;

			&.no-img {
				background: $light-grey url('/img/logo-icon-crop.svg') no-repeat bottom right / 36.5%;
			}
		}

		.info {
			display: flex;
			position: relative;
			width: 100%;
			padding: 1.125rem 1.25rem;
			flex-direction: column;

			.title {
				font-family: $body-font;
				margin-bottom: 1rem;
				font-weight: 400;
				font-size: 125%;

				&::after {
					content: '';
					display: block;
					background: $primary;
					width: 3.125rem;
					height: 0.125rem;
					margin-top: 0.875rem;
				}
			}

			.description {
				margin-bottom: 0.75rem;

				> * {
					font-size: 1rem;
				}

				> *:last-child {
					margin-bottom: 0;
				}
			}
		}

		.more {
			display: inline-block;
			position: relative;
			margin-top: auto;
			margin-left: auto;
			padding-right: 1.625rem;
			border-bottom: none;
			font-weight: 500;
			color: $primary;

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				background: $primary;
				mask: url('/img/icon-chevron-right.svg') center center / 0.5rem no-repeat;
				width: 0.5rem;
				height: 0.875rem;
				transition: all 0.25s ease;
				transform: translate(0, -50%);
			}
		}
	}

	&.products {
		.tile {
			grid-template-rows: 15rem 1fr;
		}

		.image {
			background-size: auto calc(100% - 2rem);
		}
	}

	&.partners {
		.image {
			background-color: $white;
			background-size: contain;
		}
	}
}

@media only screen and (min-width: 600px) {
	.tiles {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: $gap;

		.tile {
			width: calc((100% - $gap) / 2);

			+ .tile {
				margin-top: 0;
			}
		}
	}
}

@media only screen and (min-width: 900px) {
	.tiles {
		.tile {
			width: calc((100% - ($gap * 2)) / 3);
		}
	}
}
