// Typography

$body-font: 'Metropolis', Arial, Helvetica, sans-serif;
$heading-font: 'Byom', Arial, Helvetica, sans-serif;

// Settings

$gap: clamp(1.25rem, 1rem + 1.111vw, 2rem);
$min-width: 320px;
$max-width: 80rem;
$btn-padding: 0.625rem 1.875rem;
$btn-radius: 5px 50px;
$img-radius: 10px 50px;
$img-outline-radius: 18px 50px;

// Colours

$black: #352e30;
$dark-grey: #212121;
$medium-grey: #b3b3b3;
$light-grey: #f2f3f3;
$white: #ffffff;

$primary: #32c4ec;
$secondary: #a3cd39;

$positive: #a3cd39;
$neutral: #eabc31;
$negative: #ea3150;
