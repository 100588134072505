.actions {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	&.start {
		justify-content: flex-start;
	}

	&.end {
		flex-direction: column-reverse;
		justify-content: flex-end;
	}

	button,
	.button {
		width: 100%;
		max-width: 20rem;
		margin: 0.5rem 0;
	}
}

button,
.button {
	display: inline-block;
	position: relative;
	background: $black;
	padding: $btn-padding;
	border-radius: $btn-radius;
	border: 2px solid $black;
	box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1),
		0 10px 10px 0 rgba(0, 0, 0, 0.1);
	transition: all 0.25s ease;
	text-decoration: none;
	line-height: 1;
	text-align: center;
	font-weight: 500;
	font-size: 1.125rem;
	appearance: none;
	overflow: hidden;
	cursor: pointer;
	color: $white;
	z-index: 1;

	&:focus {
		outline: 3px solid $primary;
	}

	&:hover {
		background: $white;
		border-color: $black;
		color: $black;
	}

	&.primary {
		background: $primary;
		border-color: $primary;

		&:hover {
			background: transparent;
			color: $primary;
		}
	}

	&.secondary {
		background: $secondary;
		border-color: $secondary;

		&:hover {
			background: transparent;
			color: $secondary;
		}
	}

	&.negative {
		background: $negative;
		border-color: $negative;
		color: $white;

		&:hover {
			background: transparent;
			color: $negative;
		}
	}

	&.action {
		background: transparent;
		border-color: transparent;
		box-shadow: none;
		color: $black;

		&:hover {
			box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1),
				0 10px 10px 0 rgba(0, 0, 0, 0.1);
		}
	}

	&.hollow {
		background: transparent;
		color: $black;

		&:hover {
			background: $black;
			border-color: $black;
			color: $white;
		}

		&.primary {
			color: $primary;

			&:hover {
				background: $primary;
				border-color: $primary;
				color: $white;
			}
		}

		&.secondary {
			color: $secondary;

			&:hover {
				background: $secondary;
				border-color: $secondary;
				color: $white;
			}
		}

		&.negative {
			color: $negative;

			&:hover {
				background: $negative;
				border-color: $negative;
				color: $white;
			}
		}

		&.action {
			border-color: transparent;

			&:hover {
				background: transparent;
				color: $black;
			}
		}
	}

	&.icon-start {
		padding: 0.5rem 1rem;

		i {
			margin-right: 0.5rem;
		}
	}

	&.icon-end {
		padding: 0.5rem 1rem;

		i {
			margin-left: 0.5rem;
		}
	}
}


// Light Gallery Buttons

.lg-prev,
.lg-next {
	&:hover {
		background: rgba(0, 0, 0, 0.45);
	}
}


// Responsive

@media only screen and (min-width: 569px) {
	.actions {
		&.end {
			flex-direction: initial;
		}

		button,
		.button {
			width: auto;
			max-width: 100%;
			margin: 0.5rem;
		}
	}
}
