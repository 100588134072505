// Galleries

.gallery {
	.description {
		width: 100%;
		margin-bottom: 2rem;
	}

	figure {
		border: 1px solid transparent;
		overflow: hidden;
		cursor: pointer;

		&:hover {
			img {
				transform: scale(1.1);
			}
		}

		img {
			border-radius: 0.5rem;
			transition: all 0.25s ease;
		}

		figcaption {
			display: none;
		}
	}
}

.lg-image {
	border-radius: 0;
}

@media only screen and (min-width: 401px) {
	.gallery {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		figure {
			width: calc(100% / 2);
		}
	}
}

@media only screen and (min-width: 569px) {
	.gallery {
		figure {
			width: calc(100% / 3);
		}
	}
}

@media only screen and (min-width: 941px) {
	.gallery {
		figure {
			width: calc(100% / 4);
		}
	}
}
