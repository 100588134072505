// Accordion

.expandable {
	width: 100%;

    .expandable-title {
		display: block;
		position: relative;
		background: $light-grey;
		padding: 0.625rem 3rem 0.625rem 1rem;
		border-radius: 5px;
		transition: all 0.25s ease;
		font-weight: 500;
		font-size: 1.125rem;
		cursor: pointer;

		&:not(:first-child) {
			margin-top: 0.25rem;
		}

		&::after {
			content: '';
			position: absolute;
			top: 50%; right: 1.25rem;
			background: $black;
			mask: url('/img/icon-fa-chevron-down.svg') center center / 1rem no-repeat;
			width: 1rem;
			height: 1rem;
			transition: all 0.25s ease;
			transform: translateY(-50%);
			text-align: center;
			font-weight: 400;
			font-size: 87.5%;
		}

		&.active {
			background: $secondary;
			color: $white;

			&::after {
				background: $white;
				transform: translateY(-50%) rotate(180deg);
			}
		}

		&:hover {
			background: $secondary;
			color: $white;

			&::after {
				background: $white;
			}
		}
	}

	.expandable-content {
		display: none;
		padding: 0.75rem 1rem;

		> :last-child {
			margin-bottom: 0;
		}
	}
}
