@import '~normalize.css/normalize.css';
@import 'partials/_fonts.scss';
@import 'partials/_variables.scss';
@import 'partials/_mixins.scss';
@import 'partials/_messages.scss';
@import 'partials/_buttons.scss';
@import 'partials/_accordion.scss';
@import 'partials/_gallery.scss';
@import 'partials/_slider.scss';
@import 'partials/_tile.scss';
@import 'partials/_forms.scss';

// Global

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	font-family: $body-font;
	position: relative;
	line-height: 1.5;
	font-weight: 400;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.container {
	@include centraliser;

	.row {
		display: flex;
		margin: 0 auto;
		justify-content: space-between;

		&.wrap {
			flex-wrap: wrap;
		}
	}
}

.page-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba($black, 0.7);
	transition: all 0.25s ease;
	visibility: hidden;
	opacity: 0;
	z-index: 3;

	&.active {
		visibility: visible;
		opacity: 1;
	}
}

.sr-only {
	@include sr-only;
}

img {
	display: block;
	max-width: 100%;
	max-height: 100%;
}

a {
	transition: all 0.25s ease;
	text-decoration: none;
}

a,
button,
.button,
input[type="submit"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select,
input[type="checkbox"],
.checkbox,
input[type="radio"] {

	&:focus {
		outline: 3px solid $primary;
	}
}

// Header

header {
	background: $white;
	box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.15);

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.logo {
		border: none;
	}

	nav {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin: 0;
				padding: 0;

				a {
					font-family: $heading-font;
					border: none;
					font-weight: 400;
				}
			}
		}
	}
}

// Mobile Navigation

@media only screen and (max-width: 859px) {
	header {
		.container {
			padding-top: 0.875rem;
			padding-bottom: 0.875rem;

			&::before {
				content: '';
				position: absolute;
				inset: 0;
				background: $white;
				z-index: 4;
			}
		}

		.logo {
			z-index: 4;

			img {
				height: 1.375rem;
			}
		}

		nav {
			position: absolute;
			top: 100%;
			left: 0;
			background: darken($white, 3%);
			width: 100%;
			transform: translate(0, -100%);
			transition: all 0.25s ease;
			visibility: hidden;
			z-index: 3;

			&.active {
				transform: translate(0, 0);
				visibility: visible;
			}

			ul {
				ul {
					display: none;
					width: 100%;
				}
			}

			li {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				transition: all 0.25s ease;

				&.contact {
					a {
						background: $primary;
						font-weight: 700;
						color: $white;
					}
				}

				&:hover {
					> a {
						background: $secondary;
						color: $white;
					}

					> button {
						background: $secondary;

						.icon {
							background: $white;
						}
					}
				}

				li {
					&:hover {
						> a {
							background: $black;
							color: $white;
						}
					}
				}
			}

			a {
				display: block;
				width: 100%;
				padding: 1rem 1.25rem;
				line-height: 1;
				font-size: 1.125rem;
				color: $black;

				&.has-child {
					width: calc(100% - 3.125rem);
				}
			}

			button {
				display: block;
				background: transparent;
				width: 3.125rem;
				height: 3.125rem;
				margin: 0;
				padding: 0;
				border-radius: 0;
				border: none;
				box-shadow: none;
				transition: all 0.25s ease;
				cursor: pointer;

				&::before {
					content: none;
				}

				&:hover {
					.icon {
						background: $white;
					}
				}

				&.active {
					&:hover {
						.icon {
							background: $black;
						}
					}

					.icon {
						background: $black;
						transform: rotate(180deg);
					}
				}

				.icon {
					display: block;
					background: $black;
					mask: url('/img/icon-fa-chevron-down.svg') center 48% / 0.875rem no-repeat;
					width: 100%;
					height: 100%;
					transition: all 0.25s ease;
				}
			}

			li li {
				a {
					padding-left: 2rem;
					font-size: 1rem;
				}
			}
		}

		.toggle-nav {
			position: relative;
			background: transparent;
			width: 2.625rem;
			height: 2.625rem;
			margin: 0;
			padding: 0.75rem 0.275rem;
			border-radius: 0;
			border: none;
			box-shadow: none;
			text-indent: -999rem;
			z-index: 4;

			&::before {
				content: none;
			}

			span,
			span::before,
			span::after {
				display: block;
				position: absolute;
				background: $black;
				width: 1.875rem;
				height: 0.125rem;
			}

			span {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				transition: background 0.25s ease;

				&::before {
					content: '';
					top: -8px;
					left: 0;
					transition: top 150ms ease 0.125s, opacity 150ms ease 0.125s, background 0.25s ease;
				}

				&::after {
					content: '';
					bottom: -8px;
					left: 0;
					transition: bottom 150ms ease 0.125s, background 0.25s ease;
				}
			}

			&:hover {
				cursor: pointer;

				span,
				span::before,
				span::after {
					background: $primary;
				}
			}

			&.active {
				span,
				span::before,
				span::after {
					background: $black;
					width: 1.25rem;
				}

				span {
					transform: translate(-50%, -50%) rotate(45deg);
					transition: transform 150ms ease 0.125s, background 0.25s ease;

					&::before {
						top: 0;
						opacity: 0;
						transition: background 0.25s ease;
					}

					&::after {
						bottom: 0;
						transform: rotate(-90deg);
						transition: transform 150ms ease 0.125s, background 0.25s ease;
					}
				}

				&:hover {
					span,
					span::before,
					span::after {
						background: $primary;
					}
				}
			}
		}
	}
}

// Desktop Navigation

@media only screen and (min-width: 860px) {
	header {
		.logo {
			flex-shrink: 0;

			img {
				height: 1.875rem;
			}
		}

		nav {
			width: calc(100% - 11.75rem);

			> ul {
				display: flex;
				align-items: center;

				> li {
					position: relative;
					margin: 0;

					&:first-child {
						margin-left: auto;
					}

					&.contact {
						margin-right: 0;
						margin-left: auto;

						a {
							padding: 0.5rem 2rem;
							border: 2px solid $primary;
							border-radius: $btn-radius;
							overflow: hidden;
							font-weight: 700;
							line-height: 1;
							color: $primary;
							z-index: 0;

							&::before {
								inset: 0;
								background: $primary;
								height: auto;
								transform: translate(-100%, 0);
								z-index: -1;
							}

							&:hover {
								color: $white;

								&::before {
									background: $primary;
									transform: translate(0, 0);
								}
							}
						}
					}

					&:hover {
						a {
							&::before {
								background: $secondary;
							}
						}

						ul {
							visibility: visible;
							opacity: 1;
						}
					}

					> a {
						display: block;
						position: relative;
						padding: 2.25rem clamp(0.5rem, -0.209rem + 1.622vw, 1.25rem);
						z-index: 3;
						color: $black;

						&::before {
							content: '';
							position: absolute;
							top: calc(100% - 1.5rem);
							right: calc(clamp(0.5rem, -0.209rem + 1.622vw, 1.25rem) - 2px);
							left: calc(clamp(0.5rem, -0.209rem + 1.622vw, 1.25rem) - 2px);
							background: transparent;
							height: 3px;
							transition: all 0.25s ease;
							z-index: 3;
						}
					}

					button {
						display: none;
					}
				}

				ul {
					position: absolute;
					top: 100%;
					left: calc(-1.25rem + clamp(0.5rem, -0.209rem + 1.622vw, 1.25rem));
					background: $white;
					width: 15rem;
					box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.15);
					transition: all 0.25s ease;
					visibility: hidden;
					opacity: 0;
					z-index: 2;

					&::before {
						content: '';
						display: block;
						position: absolute;
						top: -2rem;
						right: -2rem;
						left: -2rem;
						background: $white;
						height: 2rem;
					}

					li {
						a {
							display: block;
							padding: 0.625rem 1.25rem;
							line-height: 1.5;
							font-size: 1rem;
							color: $black;

							&:hover {
								background: $black;
								color: $white;
							}
						}
					}

					ul {
						display: none;
					}
				}
			}
		}

		.toggle-nav {
			display: none;
		}
	}
}

// Footer

footer {
	position: relative;
	background-color: transparent;
	background-image: url('/img/logo-watermark.svg'), radial-gradient(at bottom left, #4F4548 20%, #352E30);
	background-position: bottom -3.75rem right -7.5rem, center center;
	background-repeat: no-repeat;
	background-size: 18.75rem, 100%;
	padding: calc(3.125rem + 5px) 0 3.125rem;
	box-shadow: inset 0 45px 40px -40px rgba(0, 0, 0, 0.5);

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background: linear-gradient(90deg, $primary, $secondary);
		width: 100%;
		height: 5px;
	}

	.footer-logo {
		display: block;
		margin-bottom: 2.75rem;
		border: none;
	}

	nav {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		li {
			margin: 0;
			padding: 0;
			font-size: 1rem;

			+ li {
				margin-top: 0.5rem;
			}
		}

		a {
			display: block;
			border: none;
			font-weight: 400;
			color: $white;

			&:hover {
				color: $primary;
			}
		}

		ul ul ul {
			margin-top: 0.5rem;
			margin-left: 1rem;
		}
	}

	nav > ul > li {
		+ li {
			margin-top: 1.75rem;
		}

		> a {
			margin-bottom: 0.75rem;
			font-weight: 700;
			font-size: 1.125rem;
		}
	}
}

.social {
	display: flex;
	margin: 3.125rem 0 1.25rem;
	padding: 0;
	list-style: none;
	flex-wrap: wrap;

	li {
		margin: 0;
		padding: 0;
		list-style: none;

		+ li {
			margin-left: 1.25rem;
		}

		a {
			display: block;
			width: 1.25rem;
			height: 1.25rem;
			border: none;
			transition: all 0.5s ease;
			text-indent: -999rem;

			&:hover {
				i {
					background: $secondary;
				}
			}

			i {
				display: block;
				background: $white;
				mask: center center / 1.25rem 1.25rem no-repeat;
				width: 100%;
				height: 100%;
				transition: all 0.375s ease;
			}
		}

		&.facebook {
			a {
				width: 0.875rem;

				i {
					mask-image: url('/img/icon-facebook.svg');
				}
			}
		}

		&.linkedin {
			a {
				i {
					mask-image: url('/img/icon-linkedin.svg');
				}
			}
		}

		&.twitter {
			a {
				i {
					mask-image: url('/img/icon-twitter.svg');
					mask-position: 0 2px;
				}
			}
		}
	}
}

.legal {
	color: rgba($white, 0.5);

	p {
		margin: 0 0 0.25rem;
		font-size: 0.75rem;
	}

	a {
		border: none;
		font-weight: 400;
		color: $medium-grey;

		&:hover {
			color: $secondary;
		}
	}
}

// Page Header

body:not(#home) .page-header {
	@include grid-template;
}

.page-header {
	position: relative;
	background: $black url('/img/placeholder-page-header.jpg') no-repeat center center;
	background-size: cover;
	padding-top: clamp(3.125rem, 9.625rem + -7.222vw, 8rem);
	padding-bottom: 3.125rem;
	grid-column: full;

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		background: rgba(0, 0, 0, 0.4);
		z-index: 0;
	}

	.page-title {
		margin-bottom: 1.25rem;
		text-shadow: 2px 3px 0 rgba(0, 0, 0, 0.15);
		grid-column: content;
		font-weight: 400;
		font-size: clamp(2.25rem, 2rem + 1.111vw, 3rem);
		color: $white;
		z-index: 1;

		&::after {
			content: '';
			display: block;
			background: $secondary;
			width: 3.125rem;
			height: 0.25rem;
			margin-top: 1.25rem;
		}
	}

	.page-subtitle {
		grid-column: content;
		z-index: 1;
	}

	.introduction {
		max-width: 38rem;
		text-shadow: 2px 3px 0 rgba(0, 0, 0, 0.15);
		grid-column: content;
		color: $white;
		z-index: 1;

		*:last-child {
			margin-bottom: 0;
		}

		p {
			font-size: clamp(1rem, 0.917rem + 0.37vw, 1.25rem);
		}
	}
}

// Main

main {
	@include grid-template;

	> section {
		margin-bottom: clamp(3.125rem, 2.5rem + 2.778vw, 5rem);
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $heading-font;
		position: relative;
		margin: 2rem 0 0.5rem;
		font-weight: 400;
		line-height: 1.2;
		color: $black;

		&:first-child {
			margin-top: 0;
		}
	}

	h1 {
		font-size: clamp(2.375rem, 2.292rem + 0.37vw, 2.625rem);
	}

	h2 {
		font-size: clamp(2.125rem, 2.042rem + 0.37vw, 2.375rem);
	}

	h3 {
		font-size: clamp(1.875rem, 1.792rem + 0.37vw, 2.125rem);
	}

	h4 {
		font-size: clamp(1.625rem, 1.542rem + 0.37vw, 1.875rem);
	}

	h5 {
		font-size: clamp(1.375rem, 1.292rem + 0.37vw, 1.625rem);
	}

	h6 {
		font-size: clamp(1.125rem, 1.042rem + 0.37vw, 1.375rem);
	}

	p {
		margin: 0 0 1rem;
		font-weight: 300;
		font-size: clamp(1rem, 0.958rem + 0.185vw, 1.125rem);
	}

	b,
	strong {
		font-weight: 700;
	}

	u {
		text-decoration: none;
		border-bottom: 1px solid;
	}

	s {
		text-decoration-thickness: 1px;
	}

	a {
		border-bottom: 1px solid;
		font-weight: 700;
		color: $primary;

		&:hover {
			border-bottom-color: transparent;
		}

		&.fr-file {
			display: inline-block;
			position: relative;
			background: transparent;
			padding: 0.375rem 2rem 0.375rem 3.75rem;
			border-radius: $btn-radius;
			border: 2px solid $primary;
			box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1),
				0 10px 10px 0 rgba(0, 0, 0, 0.1);
			letter-spacing: 0;
			overflow: hidden;
			font-size: 1.125rem;
			color: $primary;

			&::before {
				content: '';
				display: flex;
				position: absolute;
				top: calc(50% - 1px);
				left: 2rem;
				background: $primary;
				mask: url('/img/icon-download.svg') center center / 1.125rem no-repeat;
				width: 1.25rem;
				height: 1.25rem;
				padding: 0;
				transition: all 0.25s ease;
				transform: translate(0, -50%);
				justify-content: center;
				align-items: center;
				font-weight: 300;
				z-index: 0;
			}

			&::after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				background: $primary;
				width: 0;
				height: auto;
				border-radius: 0 30px;
				transition: all 0.25s ease;
				z-index: -1;
			}

			&:hover {
				color: $white;

				&::before {
					background: $white;
				}

				&::after {
					width: 100%;
				}
			}
		}
	}

	img {
		&.fr-dib {
			margin: 0 auto;
		}

		&.fr-dii {
			margin-top: 0;

			&.fr-fil {
				margin-right: 1rem;
				margin-bottom: 2rem;
			}

			&.fr-fir {
				margin-bottom: 2rem;
				margin-left: 1rem;
			}
		}

		&.fr-rounded {
			border-radius: $img-radius;
		}

		&.fr-bordered {
			border: none;
			border-bottom: 5px solid $primary;
		}

		&.fr-shadow {
			box-shadow: 0px 10px 20px 0px rgba($black, 0.15);
		}
	}

	.fr-img-caption {
		width: 100%;
		max-width: 31.25rem;

		.fr-inner {
			display: block;
			background: $light-grey;
			margin-top: 0.625rem;
			padding: 1rem 1.25rem;
			border-left: 0.25rem solid $secondary;
			font-size: 0.875rem;
		}
	}

	ul {
		margin: 0 0 1.5rem;
		padding-left: 1.5rem;
		list-style-type: disc;

		li {
			margin-bottom: 0.5rem;
			padding-left: 0.75rem;
			font-weight: 300;
			font-size: clamp(100%, 4vw, 112.5%);
		}

		ul {
			margin-bottom: 0;
		}

		&.checklist {
			padding-left: 0.5rem;

			li {
				display: block;
				position: relative;
				margin-bottom: 0.75rem;
				padding: 0 0 0 2rem;
				transition: all 0.25s ease;
				font-weight: 300;

				&::before {
					content: '';
					display: inline-block;
					position: absolute;
					top: 1px;
					left: 0;
					background: transparent url('/img/icon-checkmark.svg') no-repeat center center;
					width: 24px;
					height: 21px;
					margin-right: 0.75rem;
					transition: all 0.25s ease;
					vertical-align: text-top;
				}
			}
		}
	}

	ol {
		margin: 0 0 1.5rem;
		padding-left: 1.5em;
		list-style: decimal;

		&.lower-alpha {
			list-style: lower-alpha;
		}

		li {
			margin-bottom: 0.5em;
			padding-left: 0.75rem;
			font-weight: 300;
		}

		ol {
			margin-bottom: 0;
		}
	}

	hr {
		background: linear-gradient(90deg, $primary, $secondary);
		height: 3px;
		margin: clamp(3.125rem, 2.5rem + 2.778vw, 5rem) clamp(-2rem, 0.667rem + -2.963vw, 0rem);
		border: 0 none;
	}

	blockquote {
		@include blockquote-colors(0);

		background: rgba($black, 0.03);
		margin: 0 0 1rem;
		padding: 1.25rem;
		border-left: 0.75rem solid $primary;

		> :last-child {
			margin-bottom: 0;
		}
	}

	.responsive-table {
		overflow-x: auto;
	}

	table {
		width: 100%;
		margin: 0 0 1rem;

		tr {
			background: $light-grey;

			&:nth-child(even) {
				background: transparent;
			}

			th {
				background: $black;
				padding: 0.5rem 1rem;
				text-align: left;
				font-weight: 300;
				font-size: 1.125rem;
				color: $white;
			}

			td {
				padding: 0.5rem 1rem;
				font-weight: 300;
				font-size: 1.125rem;

				&.fr-highlighted {
					background: $secondary;
					color: $white;
				}

				&.amount {
					position: relative;
					padding-left: 2rem;
					text-align: right;

					&::before {
						content: '$';
						position: absolute;
						left: 1rem;
					}
				}

				.fr-file {
					display: block;
					background: $white;
					width: 2.5rem;
					height: 2.5rem;
					padding: 0;
					border-radius: 3px;
					border: 0 none;
					text-indent: -999rem;

					&::before {
						left: 50%;
						text-indent: initial;
						transform: translate(-50%, -50%);
					}

					&::after {
						content: none;
					}

					&:hover {
						background: $primary;
					}
				}
			}
		}
	}
}

section.empty {
	width: 100%;
	grid-column: content;
	text-align: center;
}

.content-block {
	display: flex;
	grid-column: content;
	justify-content: space-between;
	flex-wrap: wrap;

	> div {
		width: 100%;

		+ div {
			margin-top: clamp(3.125rem, 2.5rem + 2.778vw, 5rem);
		}
	}

	> .text {
		margin-top: 0;
		order: 1;
	}

	> .image {
		margin-top: clamp(3.125rem, 2.5rem + 2.778vw, 5rem);
		order: 2;
	}

	&.textimage,
	&.imagetext {
		align-items: center;
	}
}

.section-image-container {
	position: relative;
	width: 100%;
	max-width: 25rem;
	margin: 0 auto;
	align-self: center;

	.water-drop,
	.leaf {
		position: absolute;
		width: 100%;
		border-radius: 0;
		transform: scale(0.75);
		opacity: 0.5;
		z-index: -1;
	}

	.water-drop {
		animation: water-spin 60s linear infinite;
	}

	.leaf {
		animation: leaf-spin 60s linear infinite;
	}

	.section-image {
		background: transparent no-repeat center center;
		background-size: cover;
		width: 100%;
		margin: 0 auto;
		padding-top: 100%;
		border-radius: $img-radius;
		box-shadow: 3px 10px 40px 10px rgba(0, 0, 0, 0.1);
	}
}

@keyframes water-spin {
	100% {
		transform: scale(0.75) rotate(360deg);
	}
}

@keyframes leaf-spin {
	0% {
		transform: scale(0.75) rotate(-180deg);
	}

	100% {
		transform: scale(0.75) rotate(180deg);
	}
}

// Pagination

.pagination {
	display: flex;
	width: 100%;
	margin: 3.125rem 0 0;
	padding: 0;
	justify-content: center;

	li {
		display: block;
		margin: 0;
		padding: 0;
		list-style: none;

		&:hover:not(.active) {
			a {
				background-color: $light-grey;
			}
		}

		&.active {
			a, span {
				background: $primary;
				font-weight: 700;
				color: $white;
			}
		}

		a, span {
			display: flex;
			background: transparent;
			width: 1.75rem;
			height: 1.75rem;
			margin: 0 0.25rem;
			padding: 0;
			border-radius: 0.25rem;
			border: none;
			transition: background-color .3s;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			text-align: center;
			font-weight: 400;
			font-size: 0.875rem;
			color: $black;
		}
	}
}

// Page Specific

// -- Home

#home {
	.page-header {
		display: flex;
		min-height: 33.75rem;
		height: calc(100vh - 4.375rem);
		max-height: 60rem;
		padding: 0;

		&::before {
			content: none;
		}

		.featured-image {
			position: absolute;
			inset: 0;
			background: transparent no-repeat center center / cover;
		}

		.panel {
			position: relative;
			background: linear-gradient(75deg, #4F4548 0%, #352E30 75%);
			width: calc(100% - 2.5rem);
			max-width: 30rem;
			margin: auto;
			padding: 2.5rem clamp(1.25rem, 0.167rem + 4.815vw, 4.5rem);
			border-radius: 1rem;
			text-align: center;

			* {
				text-shadow: 2px 3px 0 rgba(0, 0, 0, 0.25);
			}

			h1 {
				margin-bottom: clamp(1.5rem, 1rem + 2.222vw, 3rem);
				font-weight: 400;
				font-size: clamp(1.5rem, 1.167rem + 1.481vw, 2.5rem);
				color: $white;

				&::after {
					content: none;
				}
			}

			.introduction {
				p {
					font-size: clamp(0.875rem, 0.792rem + 0.37vw, 1.125rem);
				}
			}

			.button {
				margin-top: clamp(0.5rem, 0.167rem + 1.481vw, 1.5rem);
				border: 1px solid $white;
				text-shadow: none;
				box-shadow: none;
				font-size: clamp(0.875rem, 0.792rem + 0.37vw, 1.125rem);
			}
		}
	}

	.page-slider {
		display: flex;
		min-height: 33.75rem;
		height: calc(100vh - 4.375rem);
		max-height: 60rem;
		margin-bottom: clamp(3.125rem, 2.5rem + 2.778vw, 5rem);
		grid-column: full;

		.slick-list {
			display: flex;
		}

		.slick-track {
			display: flex;
		}

		.slick-slide {
			display: flex;
			background: transparent no-repeat center center / cover;
			padding: 0;
		}

		.slick-dots {
			right: 0;
			bottom: 1.25rem;
			left: 0;
		}

		.featured-image {
			position: absolute;
			inset: 0;
			background: transparent no-repeat center center / cover;
		}

		.panel {
			position: relative;
			background: linear-gradient(75deg, #4F4548 0%, #352E30 75%);
			width: calc(100% - 2.5rem);
			max-width: 30rem;
			margin: auto;
			padding: 2.5rem clamp(1.25rem, 0.167rem + 4.815vw, 4.5rem);
			border-radius: 1rem;
			text-align: center;

			* {
				text-shadow: 2px 3px 0 rgba(0, 0, 0, 0.25);
			}

			.headline {
				margin-bottom: clamp(1.5rem, 1rem + 2.222vw, 3rem);
				font-weight: 400;
				font-size: clamp(1.5rem, 1.167rem + 1.481vw, 2.5rem);
				color: $white;

				&::after {
					content: none;
				}
			}

			.description {
				font-size: clamp(0.875rem, 0.792rem + 0.37vw, 1.125rem);
				color: $white;

				> *:last-child {
					margin-bottom: 0;
				}
			}

			.button {
				margin-top: clamp(0.5rem, 0.167rem + 1.481vw, 1.5rem);
				border: 1px solid $white;
				text-shadow: none;
				box-shadow: none;
				font-size: clamp(0.875rem, 0.792rem + 0.37vw, 1.125rem);
			}
		}
	}
}

#statistics {
	position: relative;
	background: $light-grey;
	padding: clamp(3.125rem, 2.5rem + 2.778vw, 5rem) 0;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		background: $light-grey;
		width: 100vw;
		height: 100%;
		transform: translate(-50%, -50%);
		z-index: -1;
	}

	> div {
		max-width: 20rem;
	}

	h3 {
		margin-bottom: 0.75rem;
		font-weight: 600;
		font-size: 3rem;
	}

	h4 {
		margin-top: 0;
		margin-bottom: 1.25rem;
		font-weight: 600;
		font-size: 1.5rem;
	}

	p {
		font-size: 1rem;
	}
}

#case-studies {
	grid-column: content;

	.tiles {
		margin-bottom: clamp(3.125rem, 2.5rem + 2.778vw, 5rem);
	}

	.button {
		display: block;
		width: fit-content;
		margin-right: auto;
		margin-left: auto;
	}
}

#home #partners {
	position: relative;
	padding: clamp(3.125rem, 2.5rem + 2.778vw, 5rem) 0;
	grid-column: content;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		background: $secondary;
		width: 100vw;
		height: 100%;
		transform: translate(-50%, -50%);
		z-index: -1;
	}

	h3 {
		margin-bottom: clamp(2rem, 1.625rem + 1.667vw, 3.125rem);
		text-align: center;
		font-weight: 400;
		color: $white;
	}

	img {
		border-radius: 0;
	}
}

.partner-logos {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin: 0;
		padding: clamp(1rem, 0.667rem + 1.481vw, 2rem);
	}
}

#testimonials {
	position: relative;
	padding-bottom: 4rem;
	grid-column: content;

	.testimonials {
		max-width: 36rem;
		margin: 0 auto;

		.slick-slide {
			height: auto;
		}
	}

	.testimonial {
		&::before,
		&::after {
			content: none;
		}

		.image {
			position: relative;
			width: 100%;
			max-width: 7.5rem;
			margin: 0 auto 2rem;
			border-radius: 10rem;
			box-shadow: 2px 2px 10px 0 rgba($black, 0.15);

			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				border-radius: 15px 50px 15px 50px;
				overflow: hidden;
				z-index: -1;
			}

			&::before {
				top: -5px;
				left: -5px;
				background-color: $primary;
			}

			&::after {
				top: 5px;
				left: 5px;
				background-color: $secondary;
			}

			img {
				border-radius: $img-radius;
			}
		}

		.content {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.quote {
				width: 100%;
				margin: 0 0 0.75rem;
				text-align: center;

				> *:last-child {
					margin-bottom: 0;
				}
			}

			.name {
				margin: 0 auto;
				font-weight: 500;
			}
		}
	}

	.prev,
	.next {
		position: absolute;
		bottom: 0; left: 50%;
		background: transparent no-repeat center center / 11px 20px;
		width: 2.5rem;
		height: 2.5rem;
		margin: 0;
		padding: 0;
		border-radius: 10rem;
		border: 1px solid $primary;
		box-shadow: none;

		&:hover {
			border-color: $primary;
			color: $dark-grey;
		}
	}

	.prev {
		background-position-x: 46%;
		background-image: url('/img/icon-chevron-left-light.svg');
		transform: translate(calc(-50% - 1.75rem), 0);
		padding-right: 1px;
	}

	.next {
		background-position-x: 54%;
		background-image: url('/img/icon-chevron-right-light.svg');
		transform: translate(calc(-50% + 1.75rem), 0);
		padding-left: 1px;
	}
}

// -- Products Index

.categories {
	margin-bottom: clamp(3.125rem, 2.5rem + 2.778vw, 5rem);
	grid-column: content;

	.category {
		display: grid;
		position: relative;
		background: $white;
		height: auto;
		margin: 0;
		border-bottom: none;
		border-radius: 1rem;
		box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.1);
		grid-template-rows: 9.375rem 1fr;
		overflow: hidden;
		color: $black;

		+ .category {
			margin-top: 2rem;
		}

		.image {
			position: relative;
			background: $light-grey url('/img/placeholder-page-header.jpg') no-repeat center center / cover;
			width: 100%;

			&.no-img {
				background: $light-grey url('/img/logo-icon-crop.svg') no-repeat bottom right / 36.5%;
			}
		}

		.info {
			display: flex;
			position: relative;
			width: 100%;
			padding: 1.125rem 1.25rem;
			flex-direction: column;

			.title {
				font-family: $body-font;
				margin-bottom: 1rem;
				font-weight: 400;
				font-size: 125%;

				&::after {
					content: '';
					display: block;
					background: $primary;
					width: 3.125rem;
					height: 0.125rem;
					margin-top: 0.875rem;
				}
			}

			.description {
				margin-bottom: 0.75rem;

				> *:last-child {
					margin-bottom: 0;
				}
			}
		}

		.more {
			display: inline-block;
			position: relative;
			margin-top: auto;
			margin-left: auto;
			padding-right: 1.625rem;
			border-bottom: none;
			font-weight: 500;

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				background: $primary;
				mask: url('/img/icon-chevron-right.svg') center center / 0.5rem no-repeat;
				width: 0.5rem;
				height: 0.875rem;
				transition: all 0.25s ease;
				transform: translate(0, -50%);
			}
		}
	}
}

// -- Individual Product

.product-page-header {
	background-image: url('/img/page-header-product.jpg');
}

.single-product {
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	flex-wrap: wrap;
	grid-column: content;
}

.variant-toggle {
	display: flex;
	width: 100%;
	margin: 0 0 3.125rem;
	justify-content: center;

	button {
		margin: 0;
		padding: 0.5rem 1.125rem;
		border-radius: 0;
		border-width: 1.5px;
		box-shadow: none;
		text-transform: uppercase;
		font-size: 1rem;

		&:first-child {
			border-radius: 0.625rem 0 0 0.625rem;
		}

		&:last-child {
			border-radius: 0 0.625rem 0.625rem 0;
		}

		&.active {
			background: $primary;
			color: $white;
		}

		+ button {
			border-left: none;
		}
	}
}

.product-images {
	position: relative;
	background: $light-grey;
	width: 100%;
	max-width: calc(36rem);
	margin-bottom: 3.125rem;
	padding: min(100%, 36rem) 2rem 0;
	border-radius: $img-radius;

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		border-radius: $img-outline-radius;
		overflow: hidden;
		z-index: -1;
	}

	&::before {
		top: -0.5rem;
		left: -0.5rem;
		background-color: $primary;
	}

	&::after {
		top: 0.5rem;
		left: 0.5rem;
		background-color: $secondary;
	}

	.product-images-slider {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		max-width: calc(100% - 4rem);
		max-height: calc(100% - 4rem);
		transform: translate(-50%, -50%);

		.product-image {
			background: transparent no-repeat center center / contain;
			width: 100%;
			height: 100%;

			&::before,
			&::after {
				content: none;
			}
		}

		.slick-list {
			width: 100%;
			height: 100%;
		}

		.slick-track {
			display: flex;
			height: 100%;
			max-height: 100%;
			justify-content: center;
			align-items: center;
		}
	}

	.slick-dots {
		right: 15px;
		bottom: 20px;

		li:only-child {
			display: none;
		}
	}
}

.product-description {
	width: 100%;
	max-width: 36rem;

	h4 {
		text-transform: uppercase;
		font-weight: 600;
		font-size: 0.875rem;
		color: $primary;
	}

	table {
		td {
			font-size: 1rem;

			&:first-child {
				font-weight: 700;
			}
		}
	}
}

@media only screen and (max-width: 599px) {
	.product-description,
	.product-details {
		table {
			tr {
				display: flex;
				flex-wrap: wrap;

				td {
					width: 100%;

					&:first-child {
						padding-bottom: 0;
					}

					+ td {
						padding-top: 0;
					}
				}
			}
		}
	}
}

.related-products {
	display: flex;
	background: $light-grey;
	margin-bottom: 0;
	padding: clamp(3.125rem, 2.5rem + 2.778vw, 5rem) $gap;
	justify-content: center;
	grid-column: full;
	flex-wrap: wrap;
	gap: 2rem;

	h3 {
		width: 100%;
		margin-bottom: 2rem;
		text-align: center;
		font-weight: 400;
	}

	.product {
		display: grid;
		position: relative;
		background: $white;
		width: 100%;
		max-width: 17.5rem;
		height: auto;
		border-bottom: none;
		border-radius: 1rem;
		box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.1);
		grid-template-rows: 11.875rem 1fr;
		overflow: hidden;
		color: $black;

		.image {
			position: relative;
			background: $white no-repeat center center / calc(100% - 2rem);
			width: 100%;
		}

		.title {
			font-family: $heading-font;
			background: $primary;
			margin: 0;
			padding: 1rem 1.25rem;
			font-weight: 600;
			font-size: 1.25rem;
			color: $white;
		}
	}
}

// -- Solutions

#co2-recovery-utilisation {
	.category {
		.title {
			margin-bottom: 0;

			&::after {
				content: none;
			}
		}
	}
}

// -- Projects

.project {
	.product-details {
		width: 100%;
		max-width: 36rem;

		h4 {
			text-transform: uppercase;
			font-weight: 600;
			font-size: 0.875rem;
			color: $primary;
		}

		table {
			td {
				font-size: 1rem;

				&:first-child {
					font-weight: 700;
				}
			}
		}
	}

	.product-images {
		max-width: 100%;
		padding-top: 75%;

		.product-images-slider {
			max-width: 100%;
			max-height: 100%;
			border-radius: $img-radius;
			overflow: hidden;

			.product-image {
				background-size: cover;
			}
		}
	}
}

// -- Executive Team

.people {
	display: grid;
	grid-column: content;
	gap: 5rem;
}

.person {
	max-width: 25rem;
	margin: 0 auto;

	.person-img {
		position: relative;
		background: $primary no-repeat center center / cover;
		margin: 0 0 2rem;
		padding-top: 100%;
		border-radius: $img-radius;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			border-radius: $img-outline-radius;
			overflow: hidden;
			z-index: -1;
		}

		&::before {
			top: -0.5rem;
			left: -0.5rem;
			background-color: $primary;
		}

		&::after {
			top: 0.5rem;
			left: 0.5rem;
			background-color: $secondary;
		}
	}

	.person-name {
		margin: 0;
		padding: 0 0.5rem;
	}

	.person-title {
		margin: 0 0 1rem;
		padding: 0 0.5rem;
		font-weight: 600;
		color: $primary;
	}

	.person-content {
		padding: 0 0.5rem;

		> *:last-child {
			margin-bottom: 0;
		}
	}
}

// -- News

#news {
	.filters {
		button {
			padding: 0.375rem 1.375rem;
			font-size: 0.875rem;
		}
	}
}

.featured-post {
	grid-column: content;

	.image {
		position: relative;
		background: $light-grey no-repeat center center / cover;
		width: 100%;
		padding-top: 75%;
		border-radius: $img-radius;

		&.has-img {
			background: $light-grey no-repeat center center / cover;
		}

		&.no-img {
			background: $light-grey url('/img/logo-icon-crop.svg') no-repeat bottom right / 36.5%;
		}
	}

	.post-info {
		display: flex;
		position: relative;
		background: $secondary;
		padding: 1.75rem 1.25rem;
		border-radius: $img-radius;
		flex-direction: column;

		&::before {
			content: '';
			background: $secondary;
			position: absolute;
			inset: 0;
			transform: translate(0, -25%);
			z-index: -1;
		}

		a {
			border-bottom: none;
			color: $white;
		}
	}

	.post-date {
		width: 100%;
		margin: 0 0 0.625rem;
		font-size: clamp(0.875rem, 0.833rem + 0.185vw, 1rem);
	}

	.post-title {
		width: 100%;
		margin: 0 0 0.625rem;
		font-weight: 400;
		font-size: clamp(2rem, 1.833rem + 0.741vw, 2.5rem);

		a {
			font-weight: 400;
		}
	}

	.post-extract {
		width: 100%;
		margin: 0 0 0.5rem;
		font-size: clamp(1rem, 0.917rem + 0.37vw, 1.25rem);

		> * {
			margin-bottom: 0;
		}
	}

	.more {
		display: inline-block;
		position: relative;
		margin-top: auto;
		margin-left: auto;
		padding-right: 1.125rem;
		border-bottom: none;
		font-weight: 700;
		font-size: clamp(1rem, 0.958rem + 0.185vw, 1.125rem);

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			background: $white;
			mask: url('/img/icon-chevron-right-solid.svg') center center / 7px no-repeat;
			width: 7px;
			height: 11px;
			transition: all 0.25s ease;
			transform: translate(0, -50%);
		}
	}
}

.filters {
	display: flex;
	// background: $light-grey;
	max-width: 20rem;
	margin: 0 auto clamp(3.125rem, 2.5rem + 2.778vw, 5rem);
	padding: 1rem;
	justify-content: center;
	grid-column: content;
	flex-wrap: wrap;
	gap: 1rem;

	fieldset {
		width: 100%;
	}

	select {
		background-position: right 0.5rem top 50%;
		background-size: 0.625rem;
		max-width: 20rem;
		margin-bottom: 0;
		padding: 0.5rem 1.875rem 0.5rem 0.5rem;
		font-size: 0.875rem;
	}

	input {
		max-width: 20rem;
		margin-bottom: 0;
		padding: 0.5rem;
		font-size: 0.875rem;
	}
}

.posts {
	grid-column: content;
}

.post {
	display: grid;
	position: relative;
	background: $white;
	max-width: 25rem;
	height: auto;
	margin: 0 auto;
	border-bottom: none;
	border-radius: 1rem;
	box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.1);
	grid-template-rows: 9.375rem 1fr;
	overflow: hidden;
	color: $black;

	&:hover {
		transform: scale(1.05);
	}

	+ .post {
		margin-top: 2rem;
	}

	.image {
		position: relative;
		width: 100%;

		&.has-img {
			background: $light-grey no-repeat center center / cover;
		}

		&.no-img {
			background: $light-grey url('/img/logo-icon-crop.svg') no-repeat bottom right / 36.5%;
		}
	}

	.info {
		display: flex;
		position: relative;
		width: 100%;
		padding: 1.125rem 1.25rem;
		flex-direction: column;

		.post-date {
			margin-bottom: 0.75rem;
			font-size: 0.75rem;
			color: $medium-grey;
		}

		.post-title {
			font-family: $body-font;
			margin-top: 0;
			margin-bottom: 0.75rem;
			font-weight: 500;
			font-size: 1rem;
		}
	}

	.status {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		background: $light-grey;
		width: fit-content;
		padding: 2px 8px 1px 19px;
		box-shadow: 0 0 20px 10px rgba($black, 0.15);
		border-radius: 1rem;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 0.5625rem;

		&.in-progress {
			&::before {
				background: $primary;
			}
		}

		&.completed {
			&::before {
				background: $secondary;
			}
		}

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 6px;
			background: $dark-grey;
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 1rem;
			transform: translate(0, -50%);
		}
	}

	.more {
		display: inline-block;
		position: relative;
		margin-top: auto;
		margin-left: auto;
		padding-right: 0.875rem;
		border-bottom: none;
		font-weight: 600;
		font-size: 0.875rem;
		color: $primary;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			background: $primary;
			mask: url('/img/icon-chevron-right-solid.svg') center center / 6px no-repeat;
			width: 6px;
			height: 10px;
			transition: all 0.25s ease;
			transform: translate(0, -50%);
		}
	}
}

// -- News Post

.post-header {
	@include grid-template;

	position: relative;
	background: $black url('/img/placeholder-page-header.jpg') no-repeat center center;
	background-size: cover;
	padding-top: clamp(3.125rem, 9.625rem + -7.222vw, 8rem);
	padding-bottom: 2.5rem;
	grid-column: full;

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		background: rgba(0, 0, 0, 0.4);
		z-index: 0;
	}

	.post-date {
		margin-bottom: 0.5rem;
		grid-column: content;
		font-size: 1rem;
		color: $white;
		z-index: 1;
	}

	.post-title {
		margin-top: 0;
		margin-bottom: 0.5rem;
		text-shadow: 2px 3px 0 rgba(0, 0, 0, 0.15);
		grid-column: content;
		font-weight: 400;
		font-size: clamp(2.25rem, 2rem + 1.111vw, 3rem);
		color: $white;
		z-index: 1;
	}

	.post-author {
		display: flex;
		margin-bottom: 0;
		align-items: center;
		grid-column: content;
		font-size: 1rem;
		color: $white;
		z-index: 1;

		&::before {
			content: '';
			display: block;
			background: $secondary;
			width: 3.125rem;
			height: 0.25rem;
			margin-top: 0.125rem;
			margin-right: 1.25rem;
		}
	}
}

.post-body {
	padding-bottom: 3.125rem;
	border-bottom: 2px solid $light-grey;
	grid-column: content;
}

.post-categories {
	display: flex;
	margin: 2rem 0 0;
	padding: 0;
	list-style: none;
	flex-wrap: wrap;
	gap: 0.25rem;

	li {
		margin: 0;
		padding: 0;

		a {
			display: block;
			background: $secondary;
			padding: 0.25rem 0.625rem;
			border: none;
			text-transform: uppercase;
			font-weight: 400;
			font-size: 0.75rem;
			color: $white;

			&:hover {
				background: $black;
			}
		}
	}
}

.related-posts {
	grid-column: content;

	h3 {
		width: 100%;
		margin-bottom: 2rem;
	}
}

// -- Contact

.contact-split {
	.map-container {
		position: relative;
		height: 22rem;
		margin-top: 3.125rem;
		padding: 0.625rem;

		&::before {
			@extend %bv_hidden;
			@extend %filled_obj;

			background-color: $primary;
			width: calc(100% - 1.25rem);
			height: calc(100% - 1.25rem);
			border-radius: $img-outline-radius;
			overflow: hidden;
			z-index: -1;
		}

		&::after {
			@extend %filled_obj;

			top: 1.25rem;
			left: 1.25rem;
			background-color: $secondary;
			width: calc(100% - 1.25rem);
			height: calc(100% - 1.25rem);
			border-radius: $img-outline-radius;
			overflow: hidden;
			z-index: -1;
		}
	}

	.map {
		position: relative;
		height: 100%;
		border-radius: $img-radius;

		img {
			border-radius: 0;
		}

		button {
			border-radius: 0;
			box-shadow: none;
		}
	}
}

.contact-panel {
	@include grid-template;

	background: $secondary;
	margin-bottom: 0;
	grid-column: full;
}

#contact-form {
	width: 100%;
	max-width: 47.75rem;
	margin: clamp(3.125rem, 2.5rem + 2.778vw, 5rem) auto;
	grid-column: content;

	h3 {
		margin-top: 0;
		margin-bottom: 2rem;
		text-align: center;
	}

	button {
		width: auto;
		padding-right: 4rem;
		padding-left: 4rem;
	}
}

// Responsive

@media only screen and (min-width: 500px) {
	footer {
		nav {
			columns: 2;
			column-gap: 2rem;

			ul {
				ul {
					break-inside: avoid;
				}
			}
		}
	}
}

@media only screen and (min-width: 560px) {
	.posts,
	.related-posts {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: $gap;

		.post {
			width: calc((100% - $gap) / 2);
			margin: 0;

			+ .post {
				margin-top: 0;
			}
		}
	}
}

@media only screen and (min-width: 600px) {
	.categories {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: $gap;

		.category {
			width: calc((100% - $gap) / 2);

			+ .category {
				margin-top: 0;
			}
		}
	}

	.featured-post {
		display: grid;
		grid-template-columns: clamp(15rem, 0rem + 40vw, 36rem) 1fr;

		.post-info {
			margin: 0.625rem 0;
			padding-top: 2.75rem;
			padding-right: clamp(1.25rem, -0.089rem + 3.571vw, 3.125rem);
			padding-bottom: 2rem;
			padding-left: clamp(1.25rem, -0.089rem + 3.571vw, 3.125rem);
			flex-direction: row;
			align-content: center;
			flex-wrap: wrap;

			&::before {
				transform: translate(-25%, 0);
			}
		}
	}

	#contact-form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: clamp(1rem, -0.064rem + 3.404vw, 3rem);

		h3 {
			grid-column: 1 / 3;
		}

		.full {
			grid-column: 1 / 3;
		}

		fieldset {
			margin-top: auto;
			margin-bottom: auto;
		}

		.actions {
			margin-left: auto;

			button {
				margin: 0;
			}
		}
	}
}

@media only screen and (min-width: 620px) {
	.filters {
		width: 100%;
		max-width: 64rem;
		flex-wrap: nowrap;

		button {
			margin-top: 1.25rem;
			margin-bottom: 0;
		}
	}

	#news {
		.filters {
			max-width: 50rem;
		}
	}
}

@media only screen and (min-width: 700px) {
	footer {
		.container {
			display: grid;
			grid-template-columns: 10rem 1fr;
			grid-template-rows: 3.125rem 1fr;
			column-gap: clamp(2rem, -2.02rem + 9.189vw, 6.25rem);

			.footer-logo {
				margin-bottom: 0;
				grid-column: 1 / 2;
				grid-row: 1 / 2;
			}

			.social-legal {
				margin-top: auto;
				grid-column: 1 / 2;
				grid-row: 2 / 3;
			}

			nav {
				grid-column: 2 / 3;
				grid-row: 1 / 3;
			}
		}
	}

	#home {
		.page-header {
			display: block;

			.featured-image {
				inset: 0 0 0 50%;
			}

			.panel {
				display: flex;
				align-items: center;
				width: 50%;
				max-width: 100%;
				height: 100%;
				margin-left: 0;
				border-radius: 0;

				.content {
					max-width: 36rem;
					margin: 0 auto;
				}
			}
		}

		.page-slider {
			.slick-dots {
				width: 50%;
			}

			.featured-image {
				inset: 0 0 0 50%;
			}

			.panel {
				display: flex;
				align-items: center;
				width: 50%;
				max-width: 100%;
				height: 100%;
				margin-left: 0;
				border-radius: 0;

				.content {
					max-width: 36rem;
					margin: 0 auto;
				}
			}
		}
	}

	#testimonials {
		padding-bottom: 0;

		.testimonials {
			width: calc(100% - 9rem);
			max-width: 60rem;
		}

		.prev,
		.next {
			top: 50%; right: auto; bottom: auto; left: auto;
			transform: translate(0, -50%);
		}

		.prev {
			left: 0;
		}

		.next {
			right: 0;
		}
	}

	.people {
		grid-template-columns: 1fr 1fr;
	}

	.contact-split {
		display: flex;

		#contact-details,
		.map-container {
			width: 50%;
		}

		.map-container {
			height: auto;
			margin-top: 0;
		}
	}
}

@media only screen and (min-width: 770px) {
	.content-block {
		&.cols-1 {
			> div {
				width: 100%;
			}
		}

		&.cols-2 {
			> div {
				max-width: calc(50% - 1rem);
				flex-basis: calc(50% - 1rem);
			}
		}

		&.cols-3 {
			> div {
				max-width: calc((100% - 4rem) / 3);
				flex-basis: calc((100% - 4rem) / 3);
			}
		}

		> div {
			+ div {
				margin-top: 0;
			}
		}

		> .text {
			order: initial;
		}

		> .image {
			display: flex;
			margin-top: 0;
			order: initial;
		}
	}
}

@media only screen and (min-width: 840px) {
	.posts,
	.related-posts {
		.post {
			width: calc((100% - ($gap * 2)) / 3);
		}
	}

	.product-images {
		max-width: calc(50% - 4rem);
		padding-top: min(36rem, calc(50% - 4rem));
	}

	.product-description {
		max-width: calc(50% - 4rem);
	}

	.project {
		.product-details {
			width: calc(50% - 4rem);
		}
	}
}

@media only screen and (min-width: 860px) {
	#home {
		.page-header,
		.page-slider {
			height: calc(100vh - 6rem);
		}
	}
}

@media only screen and (min-width: 900px) {
	#testimonials {
		.testimonial {
			display: flex;
			align-items: flex-start;

			.image {
				margin: 0 2rem 0 0;
			}

			.content {
				.quote {
					text-align: left;
				}

				.name {
					margin-left: 0;
				}
			}
		}

		.slick-track {
			display: flex;
			align-items: center;
		}
	}

	.categories {
		.category {
			width: calc((100% - ($gap * 2)) / 3);
		}
	}
}

@media only screen and (min-width: 920px) {
	.partner-logos {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		li {
			width: calc(100% / 6);
			padding: 2rem 1rem;
		}
	}
}

@media only screen and (min-width: 1000px) {
	.news-post {
		--sidebar: min(23rem, 100% - (#{$gap} * 2));
		--content: min(52.875rem, 100% - var(--sidebar) - (#{$gap} * 2));

		grid-template-columns:
			[full-start]
			var(--full)
			[content-start]
			var(--content)
			[content-end]
			0
			[sidebar-start]
			var(--sidebar)
			[sidebar-end]
			var(--full)
			[full-end];
	}

	.post-body {
		margin-right: 4.375rem;
		padding-right: 4.375rem;
		padding-bottom: 0;
		border-right: 2px solid $light-grey;
		border-bottom: none;
	}

	.post-sidebar {
		grid-column: sidebar;
	}

	.related-posts {
		display: block;
		grid-column: sidebar;

		.post {
			width: 100%;
			grid-template-columns: 7rem 1fr;
			grid-template-rows: auto;

			+ .post {
				margin-top: 2rem;
			}

			.image {
				&.no-img {
					background-size: 75%;
				}
			}
		}
	}
}

@media only screen and (min-width: 1060px) {
	.partner-logos {
		li {
			width: calc(100% / 7);
		}
	}

	.people {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media only screen and (min-width: 1140px) {
	.posts {
		.post {
			width: calc((100% - ($gap * 3)) / 4);
		}
	}
}

@media only screen and (min-width: 1200px) {
	.partner-logos {
		li {
			width: calc(100% / 8);
		}
	}
}
