.message {
	padding: 0.75rem 0;
	text-align: center;
	clear: both;

	p {
		margin: 0 auto;
	}

	&.negative {
		background: $negative;
		margin-bottom: 1rem;
		text-align: center;
		color: $white;

		p {
			margin: 0;
		}
	}

	&.positive,
	&.success {
		background: $positive;
		color: $white;
	}
}

span.error {
	display: block;
	background: $negative;
	width: 100%;
	margin: 0 0 2rem;
	padding: 0.5rem 0.75rem;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	font-size: 0.8125rem;
	color: $white;

	&:empty {
		display: none;
	}
}

p.error {
	text-align: center;
	color: $negative;
}
