// Byom

@font-face {
    font-family: 'Byom';
    src: local('Byom'),
        url('/fonts/Byom-Light.woff2') format('woff2'),
        url('/fonts/Byom-Light.woff') format('woff'),
        url('/fonts/Byom-Light.ttf') format('truetype'),
        url('/fonts/Byom-Light.svg#Byom-Light') format('svg');
    font-weight: 100 300;
    font-style: normal;
}

@font-face {
    font-family: 'Byom';
    src: local('Byom'),
        url('/fonts/Byom-Regular.woff2') format('woff2'),
        url('/fonts/Byom-Regular.woff') format('woff'),
        url('/fonts/Byom-Regular.ttf') format('truetype'),
        url('/fonts/Byom-Regular.svg#Byom-Regular') format('svg');
    font-weight: 400 500;
    font-style: normal;
}

@font-face {
    font-family: 'Byom';
    src: local('Byom'),
        url('/fonts/Byom-Bold.woff2') format('woff2'),
        url('/fonts/Byom-Bold.woff') format('woff'),
        url('/fonts/Byom-Bold.ttf') format('truetype'),
        url('/fonts/Byom-Bold.svg#Byom-Bold') format('svg');
    font-weight: 600 900;
    font-style: normal;
}

// Metropolis

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Thin.woff2') format('woff2'),
        url('/fonts/Metropolis-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Thin-Italic.woff2') format('woff2'),
        url('/fonts/Metropolis-Thin-Italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Extra-Light.woff2') format('woff2'),
        url('/fonts/Metropolis-Extra-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Extra-Light-Italic.woff2') format('woff2'),
        url('/fonts/Metropolis-Extra-Light-Italic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Light.woff2') format('woff2'),
        url('/fonts/Metropolis-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Light-Italic.woff2') format('woff2'),
        url('/fonts/Metropolis-Light-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Regular.woff2') format('woff2'),
        url('/fonts/Metropolis-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Regular-Italic.woff2') format('woff2'),
        url('/fonts/Metropolis-Regular-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Medium.woff2') format('woff2'),
        url('/fonts/Metropolis-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Medium-Italic.woff2') format('woff2'),
        url('/fonts/Metropolis-Medium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Semi-Bold.woff2') format('woff2'),
        url('/fonts/Metropolis-Semi-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Semi-Bold-Italic.woff2') format('woff2'),
        url('/fonts/Metropolis-Semi-Bold-Italic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Bold.woff2') format('woff2'),
        url('/fonts/Metropolis-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Bold-Italic.woff2') format('woff2'),
        url('/fonts/Metropolis-Bold-Italic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Extra-Bold.woff2') format('woff2'),
        url('/fonts/Metropolis-Extra-Bold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Extra-Bold-Italic.woff2') format('woff2'),
        url('/fonts/Metropolis-Extra-Bold-Italic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Black.woff2') format('woff2'),
        url('/fonts/Metropolis-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
        url('/fonts/Metropolis-Black-Italic.woff2') format('woff2'),
        url('/fonts/Metropolis-Black-Italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}
