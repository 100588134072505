@mixin centraliser {
	position: relative;
	max-width: $max-width;
	margin: 0 auto;
	padding: 0 $gap;
}

@mixin sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: none;
	clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
	clip: rect(0 0 0 0);
	white-space: nowrap;
	overflow: hidden;
}

@mixin grid-template {
	--full: minmax(#{$gap}, 1fr);
	--content: min(76rem, 100% - (#{$gap} * 2));
	--feature: minmax(0, 5rem);

	display: grid;
	grid-template-columns:
		[full-start]
		var(--full)
		[feature-start]
		var(--feature)
		[content-start]
		var(--content)
		[content-end]
		var(--feature)
		[feature-end]
		var(--full)
		[full-end];
}

@mixin blockquote-colors($n) {
	$n: $n + 1;

	blockquote {
		@if $n % 2 == 0 {
			border-color: $primary;
		} @else {
			border-color: $secondary;
		}

		@if ($n < 5) {
			@include blockquote-colors($n);
		}
	}
}
