form {
	.full {
		width: 100%;
	}

	.half {
		width: 50%;
		padding: 0 0.5rem;

		&:nth-of-type(even) {
			padding-right: 0;
		}

		&:nth-of-type(odd) {
			padding-left: 0;
		}

		&.offset-right {
			margin-right: 50%;
		}

		&.offset-left {
			margin-left: 50%;
		}
	}

	button,
	input[type="submit"] {
		margin: 2rem auto 0;
	}
}

fieldset {
	margin: 0;
	padding: 0;
	border: none;
}

label {
	display: block;
	margin-bottom: 0.375rem;
	padding-left: 0.25rem;
	text-transform: uppercase;
	line-height: 1.2;
	font-weight: 600;
	font-size: 0.75rem;

	input {
		margin-right: 1rem;
	}
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
	display: block;
	font-family: $body-font;
	background: $white;
	width: 100%;
	height: auto;
	margin-bottom: 2rem;
	padding: 1rem;
	border-radius: 5px;
	border: 1px solid $medium-grey;
	resize: vertical;
	color: $black;
	clear: left;

	&.error {
		margin-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		border-bottom: none;
	}
}

textarea {
	min-height: 10rem;
}

select {
	display: block;
	font-family: $body-font;
	background: $white url('/img/icon-fa-chevron-down.svg') no-repeat;
	background-size: 0.875rem;
	background-position: right 1rem top 50%;
	width: 100%;
	height: auto;
	margin-bottom: 2rem;
	padding: 1rem;
	border-radius: 5px;
	border: 1px solid $medium-grey;
	outline-color: $primary;
	text-overflow: ellipsis;
	appearance: none;
	color: $black;
	clear: left;
}

// Checkbox (add class="checkbox" to the checkbox type input)

.checkbox {
	position: absolute;
	opacity: 0;

	& + label {
		position: relative;
		padding: 0.125rem 0 0.125rem 2rem;
		line-height: 1.5;
		cursor: pointer;

		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			background: $white;
			width: 1.25rem;
			height: 1.25rem;
			border-radius: 5px;
			border: none;
		}
	}

	&:hover + label::before {
		// background: $primary
		outline: 3px solid $primary;
	}

	&:focus + label::before {
		outline: 3px solid $primary;
	}

	&:checked {
		& + label {
			&::before {
				// background: $primary;
			}

			&::after {
				content: '';
				position: absolute;
				inset: 0;
				background: $black;
				mask: url('/img/icon-check.svg') center center / 0.875rem no-repeat;
				width: 1.25rem;
				height: 1.25rem;
				// width: 2px;
				// height: 2px;
				// box-shadow: 2px 0 0 $black,
				// 			4px 0 0 $black,
				// 			4px -2px 0 $black,
				// 			4px -4px 0 $black,
				// 			4px -6px 0 $black,
				// 			4px -8px 0 $black;
				// transform: rotate(45deg);
			}
		}
	}

	&:disabled {
		& + label {
			cursor: auto;
			color: $white;

			&::before {
				background: $light-grey;
				box-shadow: none;
			}
		}
	}
}


// Radio Input

[type="radio"] {
	position: absolute;
	left: -9999px;

	&:checked {
		transform: scale(1);
		opacity: 1;
	}

	&:not(:checked) {
		transform: scale(0);
		opacity: 0;
	}

	& + label {
		display: inline-block;
		position: relative;
		padding-left: 25px;
		line-height: 20px;
		cursor: pointer;

		&::before {
			content: '';
			position: absolute;
			top: 0; left: 0;
			background: $white;
			width: 16px;
			height: 16px;
			border-radius: 100%;
			border: 1px solid $primary;
		}

		&::after {
			content: '';
			position: absolute;
			top: 3px; left: 3px;
			background: $primary;
			width: 12px;
			height: 12px;
			transition: all 0.3s ease;
			border-radius: 100%;
		}
	}
}
